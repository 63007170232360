<template>
  <Modal
      :title="title" btnLabel="Salvar" btnIcon="save" btnAsEvent noHeader
      @ok="submit"
  >
      <Form ref="form" v-bind="$props"></Form>
      OI
  </Modal>
</template>

<script>

import Modal from '@/components/static/Modal'
import Form from '@/components/builders/Form'
import View from '@/mixins/core/ViewBehavior'

export default {
  components: { Modal, Form },
  mixins: [View],
  methods: {
    submit () {
      this.$refs.form.submit()
    }
  },
  props: ['itemId', 'formKey', 'module']
}
</script>
